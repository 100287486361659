import { getGlobalStaticProps } from "../lib/helpers";

export default function Custom404() {
  return <h1>404 - Page Not Found</h1>;
}

export async function getStaticProps() {
  const props = await getGlobalStaticProps();
  return { props };
}
